<template>
  <div  class="row board">
    
          <div class="col-md-2 align-top" id="headline-container">
            <h1>Schulzentrum Blaufelden</h1>
          </div>
          <div class="col-md-3" style="border-left:1px solid white;border-right:1px solid white">
            <h2><strong>"Fit in die Zukunft"</strong></h2>
            <span style="font-size:3.5em;"><strong>So geht`s!</strong></span>
            </h2>
            <div style="text-align:center;margin-top:8em;">
          <img src="/wegweiser.png" style="width:80%" />
         </div>
          </div>
          <div class="col-md-7">
              <router-link :to="'/process/'+definition.id" v-for="definition in definitions">{{definition.id}}. {{definition.area}}</router-link>
          </div>
          
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Fasthand&family=Lily+Script+One&family=Nanum+Pen+Script&display=swap');

.board {
  padding:2em;
  font-family: 'Nanum Pen Script', cursive;    color:gray;
  color:white;
}
#headline-container {
  writing-mode: vertical-lr;
  text-orientation: sideways;
  text-align:center;
}
h1 {
  font-size:5em;
  font-family: 'Lily Script One', cursive;

}

h2 {
  font-size:3em;


}

a {
    display:block;
    font-size:2.2em;
    color:white;
}

a:hover {
  color:white;
  text-decoration:underline;
}

p {
  font-size:2em;
}
</style>
<script>
import processes from '../json/processes.json'
export default {
  name: 'Bors',
  components: {
   
  },
  data () {
    return {
      
    }
  },
  computed: {
    
    definitions() {
      
      return processes;
    }
  },
  methods: {
    
  }
}
</script>

<style>
p {
  line-height:1.5em;
}

</style>
